<template>
<nav>
    <div class="container">
        <div class="nav-wrapper">
            <a href="/" class="brand-logo">BINATRIX</a>
            <ul id="nav-mobile" class="right hide-on-med-and-down">
                <li v-show="$g.busy">
                    <div class="preloader-wrapper small active">
                        <div class="spinner-layer spinner-yellow-only">
                            <div class="circle-clipper left">
                                <div class="circle"></div>
                            </div>
                            <div class="gap-patch">
                                <div class="circle"></div>
                            </div>
                            <div class="circle-clipper right">
                                <div class="circle"></div>
                            </div>
                        </div>
                    </div>
                </li>
                <li v-if="user" :class="{ 'active': this.$route.path === '/admin'}">
                    <router-link to="/admin">Admin</router-link>
                </li>
                <li v-if="!user"><a href="#" @click.prevent="showLogin">Login</a></li>
                <li v-if="user"><a href="#" @click.prevent="logout">{{user.email}}</a></li>
            </ul>
            <a class="button-collapse" href="#" data-activates='nav-mobile'><i class="mdi-navigation-menu"></i></a>
        </div>
    </div>
</nav>
<div id="modal2" class="modal">
    <div class="modal-content">
        <h4>Access</h4>
        <div class="row">
            <div class="input-field col s12">
                <input id="txtUsername" type="email" class="validate" v-model="form.username">
                <label for="txtUsername">Email</label>
            </div>
        </div>
        <div class="row">
            <div class="input-field col s12">
                <input type="password" class="validate" v-model="form.password">
                <label for="txtPassword">Password</label>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <a href="#!" class="waves-effect waves-green btn-flat" @click.prevent="login">Login</a>
        <a href="#!" class="modal-close waves-effect waves-green btn-flat">Close</a>
    </div>
</div>
</template>

<script>
import {
    Base,
    auth
} from '../base'
import {
    signInWithEmailAndPassword,
    signOut
} from "../firebase";
import M from 'materialize-css'
var modal;

export default {
    mixins: [Base],
    data() {
        return {
            form: {}
        }
    },
    methods: {
        showLogin() {
            var elem = document.getElementById('modal2');
            modal = M.Modal.init(elem, {
                onOpenEnd: () => {
                    document.getElementById('txtUsername').focus();
                }
            });
            modal.open();
        },
        login() {
            var self = this;
            if (!this.form.username || !this.form.password) {
                alert('Debe ingresar las credenciales');
                return;
            }
            signInWithEmailAndPassword(auth, this.form.username, this.form.password)
                .then((userCredential) => {
                    self.user = userCredential.user;
                    console.log(self.user);
                    modal.close();
                })
                .catch(() => {
                    // const errorCode = error.code;
                    // const errorMessage = error.message;
                    alert("Invalid access");
                });
        },
        logout() {
            if (confirm('Desea finalizar la sesión?') == false)
                return;
            signOut(auth);
        }
    }
}
</script>

<style>
.preloader-wrapper {
    top: 10px;
    margin-right: 15px;
}
</style>
