import { initializeApp } from 'firebase/app'
import { getDatabase, ref, child, get, update, push, query, remove } from 'firebase/database'
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';

export const firebaseApp = initializeApp({
	apiKey: "AIzaSyA0N_XD39divml2JXlLia2cnYtURgq4bQs",
	authDomain: "binatrix-a66a8.firebaseapp.com",
	databaseURL: "https://binatrix-a66a8.firebaseio.com",
	projectId: "binatrix-a66a8",
	storageBucket: "binatrix-a66a8.appspot.com",
	messagingSenderId: "202077607185"
})

const db = getDatabase(firebaseApp)

export const dbPortal = ref(db, 'Portal')
export { child, get, update, push, query, remove, getAuth, signInWithEmailAndPassword, signOut };
