<template>
<div class="container" v-show="user && !$g.busy">
    <h4>Consultas: {{visits}}</h4> <a href="#" @click.prevent="clearCounter">Limpiar</a>
    <h4>Mensajes: {{ data.length }}</h4>
    <table class="striped" v-show="data.length > 0">
        <thead>
            <tr>
                <th>Fecha</th>
                <th>Email</th>
                <th>Nombre</th>
                <th>Empresa</th>
                <th>Mensaje</th>
                <th width="10px"></th>
            </tr>
        </thead>

        <tbody>
            <tr v-for="item in data" :key="item.Key">
                <td>{{item.FechaFmt}}</td>
                <td>{{item.Email}}</td>
                <td>{{item.Nombre}}</td>
                <td>{{item.Empresa}}</td>
                <td>{{item.Mensaje}}</td>
                <td><a href="#" @click.prevent="deleteEmail(item.Key)">Eliminar</a></td>
            </tr>
        </tbody>
    </table>
</div>
</template>

<script>
import {
    Base
} from '../base'
import {
    dbPortal,
    child,
    get,
    remove
} from "../firebase";
import moment from 'moment'

export default {
    mixins: [Base],
    data() {
        return {
            data: [],
            visits: 0
        }
    },
    methods: {
        clearCounter() {
            var self = this;
            if (confirm('Desea limpiar el contador?') == false)
                return;
            remove(child(dbPortal, 'Visitas')).then(() => {
                self.visits = 0;
                alert('Contador reiniciado');
            });
        },
        deleteEmail(key) {
            var self = this;
            if (confirm('Desea eliminar el correo?') == false) return;
            remove(child(dbPortal, 'Mensajes/' + key)).then(() => {
                self.listMessages();
            });
        },
        listMessages() {
            var self = this;
            get(child(dbPortal, 'Visitas')).then((snapshot) => {
                var val = snapshot.val() || 0;
                self.visits = val;
            });
            get(child(dbPortal, 'Mensajes')).then((snapshot) => {
                self.data = [];
                snapshot.forEach((childSnapshot) => {
                    var data = childSnapshot.val();
                    var key = childSnapshot.key;
                    self.data.push({
                        Key: key,
                        Fecha: new Date(data.Fecha),
                        FechaFmt: moment(new Date(data.Fecha)).format('DD/MM/YYYY HH:mm'),
                        Email: data.Email,
                        Nombre: data.Nombre,
                        Empresa: data.Empresa,
                        Mensaje: data.Mensaje
                    });
                    // console.log(data.Email + ";" + data.Empresa + ";" + data.Nombre + ";" + data.Mensaje.replaceAll('\n', ''));
                });
                self.data.sort((b, a) => a.Fecha - b.Fecha);
                self.$g.busy = false;
            });
        },
        onAuth() {
            this.listMessages();
        }
    },
    mounted() {
        this.$g.busy = true;
    }
};
</script>

<style scoped>
</style>
