<template>
<HeaderView />
<router-view />
</template>

<script>
import HeaderView from './components/HeaderView.vue'
import M from 'materialize-css'

export default {
    name: 'App',
    components: {
        HeaderView
    },
    mounted: function () {
        M.AutoInit()
    }
}
</script>

<style>
</style>
