import { createApp, reactive } from 'vue'
import { VueFire, VueFireAuth } from 'vuefire'
import App from './App.vue'
import Routes from './routes';
import { createWebHistory, createRouter } from "vue-router";
import { firebaseApp } from './firebase';
import 'materialize-css/dist/css/materialize.min.css'
import 'material-design-icons/iconfont/material-icons.css'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: Routes,
  linkActiveClass: "active",
  linkExactActiveClass: "active"
});

const app = createApp(App);
app.use(router);
app.use(VueFire, {
  firebaseApp,
  modules: [
    VueFireAuth()
  ]
})
app.config.globalProperties.$g = reactive({ busy: false });
app.mount('#app')