import {
    getAuth
} from "./firebase";
import M from 'materialize-css'

export const auth = getAuth();

export const Base = {
    data() {
        return {
            user: null
        };
    },
    emits: ['onAuth'],
    mounted() {
        M.AutoInit()
        var self = this;
        auth.onAuthStateChanged(function (user) {
            self.user = user;
            if (user == null) return;
            if (self.onAuth) {
                self.onAuth(user);
            }
        });
    }
}